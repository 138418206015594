import * as React from 'react';
import Facebook from '../images/facebook.svg';
import Linkedin from '../images/linkedin.svg';
import DividerComponent from '../components/divider';
import { Link } from 'gatsby';

const FooterContainer = () => (
  <footer className="w-full bg-blue-900 z-20">
    <div className="container mx-auto px-6">
      <DividerComponent className="bg-yellow-700 mb-10" />
      <section className="grid grid-cols-2 lg:grid-cols-6 gap-4 mb-8">
        <div className="text-2xl font-medium text-white">Belgravia</div>
        <div className="block lg:hidden"></div>
        <div>
          <Link to="/angielski-ogolny">
            <div className="henderson-font text-xs font-semibold text-white mb-4">GENERAL ENGLISH</div>
          </Link>
          <Link to="/angielski-ogolny">
            <div className="henderson-font text-2xs font-semibold text-white opacity-75 mb-2">ANGIELSKI OGÓLNY</div>
          </Link>
          <Link to="/angielski-konwersacje">
            <div className="henderson-font text-2xs font-semibold text-white opacity-75">ANGIELSKI KONWERSACYJNY</div>
          </Link>
        </div>
        <div>
          <Link to="/angielski-biznesowy">
            <div className="henderson-font text-xs font-semibold text-white mb-4">BUSINESS ENGLISH</div>
          </Link>
          <Link to="/angielski-biznesowy">
            <div className="henderson-font text-2xs font-semibold text-white opacity-75 mb-2">ANGIELSKI BIZNESOWY</div>
          </Link>
          <Link to="/angielski-branzowy">
            <div className="henderson-font text-2xs font-semibold text-white opacity-75 mb-2">ANGIELSKI BRANŻOWY</div>
          </Link>
          <Link to="/rozmowa-o-prace">
            <div className="henderson-font text-2xs font-semibold text-white opacity-75">ROZMOWA O PRACĘ</div>
          </Link>
        </div>
        <div>
          <Link to="/kurs-maturalny">
            <div className="henderson-font text-xs font-semibold text-white mb-4">EGZAMINY</div>
          </Link>
          <Link to="/kurs-maturalny">
            <div className="henderson-font text-2xs font-semibold text-white opacity-75 mb-2">MATURA</div>
          </Link>
          <Link to="/certyfikaty-cambridge">
            <div className="henderson-font text-2xs font-semibold text-white opacity-75 mb-2">CERTYFIKATY CAMBRIDGE</div>
          </Link>
          <Link to="/studia-w-uk-i-usa">
            <div className="henderson-font text-2xs font-semibold text-white opacity-75">STUDIA W UK & USA</div>
          </Link>
        </div>
        <div>
          <Link to="/angielski-dla-firm">
            <div className="henderson-font text-xs font-semibold text-white mb-4">DLA FIRM</div>
          </Link>
          <Link to="/angielski-dla-firm">
            <div className="henderson-font text-2xs font-semibold text-white opacity-75 mb-2">PRACOWNICY</div>
          </Link>
          <Link to="/executive-english">
            <div className="henderson-font text-2xs font-semibold text-white opacity-75">KADRA ZARZĄDZAJĄCA</div>
          </Link>
        </div>
        <Link to="/tlumaczenia">
          <div className="henderson-font text-xs font-semibold text-white">TŁUMACZENIA</div>
        </Link>
      </section>
      <section className="grid grid-cols-2 lg:grid-cols-6 gap-4 pb-8 lg:border-b lg:border-blue-800">
        <div className="hidden lg:flex">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/Szkoła-Językowa-Belgravia-100395042538242"
            className="opacity-75 mr-3"
          >
            <Facebook />
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/80991210/admin" className="opacity-75">
            <Linkedin />
          </a>
        </div>
        <Link to="/o-nas">
          <div className="henderson-font text-2xs font-semibold text-white">O NAS</div>
        </Link>
        <Link to="/wspolpraca">
          <div className="henderson-font text-2xs font-semibold text-white">WSPÓŁPRACA</div>
        </Link>
        <Link to="/cennik">
          <div className="henderson-font text-2xs font-semibold text-white">CENNIK</div>
        </Link>
        <Link to="/kontakt">
          <div className="henderson-font text-2xs font-semibold text-white">KONTAKT</div>
        </Link>
        <Link to="/polityka-prywatnosci">
          <div className="henderson-font text-2xs font-semibold text-white">POLITYKA PRYWATNOŚCI</div>
        </Link>
        <div className="flex lg:hidden">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/Szkoła-Językowa-Belgravia-100395042538242"
            className="opacity-75 mr-3"
          >
            <Facebook />
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/80991210/admin" className="opacity-75">
            <Linkedin />
          </a>
        </div>
      </section>
      <section className="hidden lg:block henderson-font text-2xs font-semibold text-white pt-8 pb-12 opacity-75">
        © 2021-2022 RAFAŁ ZYSKOWSKI
      </section>
    </div>
  </footer>
);

export default FooterContainer;
