import * as React from 'react';
import BlueArrow from '../images/blue-arrow.svg';

const GoTopComponent = () => {
  const goToTheTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  return (
    <div
      onClick={goToTheTop}
      className="hidden lg:flex fixed bottom-20 -right-2 z-20 -rotate-90 hover:-translate-y-4 transition-transform duration-300 cursor-pointer"
    >
      <div className="henderson-font text-blue-600 text-xs mr-4">DO GÓRY</div>
      <BlueArrow />
    </div>
  );
};

export default GoTopComponent;
