import * as React from 'react';
import { Link } from 'gatsby';

const DesktopNavLinkComponent = ({ currentIndex, mainLinksLength, link }) => (
  <div className={`h-full px-2 xl:px-4 ${currentIndex + 1 === mainLinksLength && 'pr-0'}`}>
    <div className="group relative h-full">
      {link.children ? (
        <Link to={link.children[0].link}>
          <div className="henderson-font h-full flex items-center text-xs font-semibold text-black group-hover:text-yellow-700 cursor-pointer transition-colors duration-300">
            {link.title}
          </div>
        </Link>
      ) : (
        <Link to={link.link}>
          <div className="henderson-font h-full flex items-center text-xs font-semibold text-black group-hover:text-yellow-700 cursor-pointer transition-colors duration-300">
            {link.title}
          </div>
        </Link>
      )}
      <div className="w-full h-1 scale-y-0 translate-y-0.5 group-hover:scale-y-100 group-hover:translate-y-0 bg-yellow-700 transition-transform duration-300"></div>
      {link.children && (
        <div className="opacity-0 group-hover:opacity-100 scale-y-0 group-hover:scale-y-100 origin-top transition-all duration-300 fixed left-0 w-full h-12 bg-white z-0"></div>
      )}
      {link.children && (
        <div
          className={`scale-y-0 group-hover:scale-y-100 flex absolute pt-4 opacity-0 group-hover:opacity-100 transition-opacity duration-500 whitespace-nowrap z-10 ${
            currentIndex > mainLinksLength / 2 && 'right-0'
          }`}
        >
          {link.children.map((el, i) => (
            <div key={i} className={`${i > 0 && 'pl-2'} ${i < link.children.length - 1 && 'pr-2'}`}>
              <Link to={el.link}>
                <div className="henderson-font text-xs font-semibold text-black border-b border-transparent hover:border-yellow-700 pb-2 cursor-pointer transition-colors duration-300">
                  {el.title}
                </div>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  </div>
);

export default DesktopNavLinkComponent;
