import * as React from 'react';
import { Helmet } from 'react-helmet';
import HeaderContainer from './header';
import FooterContainer from './footer';
import GoTopComponent from '../components/go-top';

const orphans = [
  'o których',
  'a',
  'i',
  'aż',
  'bo',
  'by',
  'iż',
  'ni',
  'że',
  'o',
  'u',
  'w',
  'z',
  'do',
  'ku',
  'na',
  'od',
  'po',
  'we',
  'za',
  'ze',
  'e',
  'aa',
  'aj',
  'am',
  'au',
  'ba',
  'ee',
  'eh',
  'ej',
  'fe',
  'fi',
  'fu',
  'ha',
  'he',
  'ho',
  'ii',
  'oj',
  'oo',
  'ot',
  'oż',
  'pa',
  'uf',
  'uu',
  'bu',
  'gę',
  'hi',
  'hm',
  'hu',
  'ko',
  'lu',
  'ts',
  'co',
  'ki',
  'se',
  'ja',
  'ty',
  'ci',
  'on',
  'mu',
  'go',
  'oń',
  'ją',
  'my',
  'wy',
  'im',
  'je',
  'ma',
  'mą',
  'me',
  'ów',
  'ta',
  'tę',
  'tą',
  'to',
  'te',
  'tu',
  'moich',
  'moje',
  'mojego',
  'mojej',
  'mojemu',
  'mych',
  'mój',
  'twoje',
  'twojego',
  'twojej',
  'twych',
  'twój',
  'tylko',
  'al',
  'ar',
  'as',
  'at',
  'bi',
  'dy',
  'er',
  'es',
  'Ew',
  'ez',
  'go',
  'id',
  'ił',
  'iw',
  'li',
  'ok',
  'om',
  'op',
  'or',
  'os',
  'oś',
  'oz',
  'ód',
  'ós',
  'su',
  'ud',
  'ul',
  'ut',
  'je',
  'ma',
  'są',
  'be',
  'ce',
  'de',
  'ef',
  'ha',
  'ka',
  'el',
  'em',
  'en',
  'pe',
  'ku',
  'er',
  'es',
  'te',
  'wu',
  'et',
  'mi',
  'my',
  'ni',
  'ny',
  'pi',
  'ro',
  'fi',
  'do',
  're',
  'mi',
  'fa',
  'la',
  'si',
  'h',
  's',
  'A',
  'π',
  'e',
  'c',
  'x',
  'y',
  'H',
  'He',
  'Li',
  'Be',
  'B',
  'C',
  'N',
  'O',
  'F',
  'Ne',
  'Na',
  'Mg',
  'Al',
  'Si',
  'P',
  'S',
  'Cl',
  'Ar',
  'K',
  'Ca',
  'Sc',
  'Ti',
  'V',
  'Cr',
  'Mn',
  'Fe',
  'Co',
  'Ni',
  'Cu',
  'Zn',
  'Ga',
  'Ge',
  'As',
  'Se',
  'Br',
  'Kr',
  'Rb',
  'Sr',
  'Y',
  'Zr',
  'Nb',
  'Mo',
  'Tc',
  'Ru',
  'Rh',
  'Pd',
  'Ag',
  'Cd',
  'In',
  'Sn',
  'Sb',
  'Te',
  'I',
  'Xe',
  'Cs',
  'Ba',
  'La',
  'Ce',
  'Pr',
  'Nd',
  'Pm',
  'Sm',
  'Eu',
  'Gd',
  'Tb',
  'Dy',
  'Ho',
  'Er',
  'Tm',
  'Yb',
  'Lu',
  'Hf',
  'Ta',
  'W',
  'Re',
  'Os',
  'Ir',
  'Pt',
  'Au',
  'Hg',
  'Tl',
  'Pb',
  'Bi',
  'Po',
  'At',
  'Rn',
  'Fr',
  'Ra',
  'Ac',
  'Th',
  'Pa',
  'U',
  'Np',
  'Pu',
  'Am',
  'Cm',
  'Bk',
  'Cf',
  'Es',
  'Fm',
  'Md',
  'No',
  'Lr',
  'Rf',
  'Db',
  'Sg',
  'Bh',
  'Hs',
  'Mt',
  'Ds',
  'Rg',
  'Cn',
  'Nh',
  'Fl',
  'Mc',
  'Lv',
  'Ts',
  'Og',
  'RP',
  'UW',
  'UJ',
  '—',
  'al.',
  'albo',
  'ale',
  'ależ',
  'b.',
  'bez',
  'bł.',
  'bm.',
  'bp',
  'br.',
  'bym',
  'byś',
  'cyt.',
  'cz.',
  'czy',
  'czyt.',
  'dn.',
  'doc.',
  'dr',
  'ds.',
  'dyr.',
  'dz.',
  'fot.',
  'gdy',
  'gdyby',
  'gdybym',
  'gdybyś',
  'gdyż',
  'godz.',
  'im.',
  'inż.',
  'jw.',
  'kol.',
  'komu',
  'ks.',
  'która',
  'które',
  'którego',
  'której',
  'któremu',
  'który',
  'których',
  'którym',
  'którzy',
  'lecz',
  'lic.',
  'max',
  'mgr',
  'm.in.',
  'min',
  'nad',
  'nie',
  'niech',
  'np.',
  'nr',
  'nr.',
  'nrach',
  'nrami',
  'nrem',
  'nrom',
  'nrowi',
  'nrów',
  'nru',
  'nry',
  'nrze',
  'nt.',
  'nw.',
  'oraz',
  'os.',
  'p.',
  'pl.',
  'pn.',
  'pod',
  'pot.',
  'prof.',
  'przed',
  'przez',
  'pt.',
  'pw.',
  'śp.',
  'św.',
  'tak',
  'tamtej',
  'tamto',
  'tej',
  'tel.',
  'tj.',
  'twoich',
  'ul.',
  'wg',
  'więc',
  'woj.',
  'żeby',
  'żebyś',
  'lub',
  'bądź',
];

const LayoutContainer = ({ children, title, description }) => {
  React.useEffect(() => {
    const elems = document.querySelectorAll(
      '.no-widows h1, .no-widows h2, .no-widows h3, .no-widows h4, .no-widows h5, .no-widows h6, .no-widows div, .no-widows p',
    );
    noWidowsAndOrphans(elems, 2);
  }, []);

  const noWidowsAndOrphans = (elems, numWords) => {
    elems.forEach((element) => {
      element.innerHTML = element.innerHTML.replace(new RegExp(orphans.map((orphan) => ` ${orphan} `).join('|'), 'g'), function (data) {
        return data.substring(0, data.length - 1) + '&nbsp;';
      });

      const wordCount = element.innerHTML.split(' ').length;
      if (wordCount >= numWords) {
        element.innerHTML = element.innerHTML.replace(/ ([^ ]*)$/, '&nbsp;$1');
      }
    });
  };

  const parseMarkdown = (description) => {
    const text = description
      .replace(/^###### (.*$)/gim, '$1')
      .replace(/^##### (.*$)/gim, '$1')
      .replace(/^#### (.*$)/gim, '$1')
      .replace(/^### (.*$)/gim, '$1')
      .replace(/^## (.*$)/gim, '$1')
      .replace(/^# (.*$)/gim, '$1')
      .replace(/^\> (.*$)/gim, '$1')
      .replace(/\*\*(.*)\*\*/gim, '$1')
      .replace(/\*(.*)\*/gim, '$1')
      .replace(/!\[(.*?)\]\((.*?)\)/gim, '')
      .replace(/\[(.*?)\]\((.*?)\)/gim, '')
      .replace(/\n$/gim, '');

    return text.trim();
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={parseMarkdown(description)} />
      </Helmet>
      <div className="w-full min-h-screen flex flex-col bg-white">
        <HeaderContainer />
        <main className="flex-1 mt-[95px] lg:mt-[125px]">{children}</main>
        <FooterContainer />
      </div>
      <GoTopComponent />
    </>
  );
};

export default LayoutContainer;
