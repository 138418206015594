import * as React from 'react';
import Envelope from '../images/envelope.svg';
import Telephone from '../images/telephone.svg';
import SmallGoldArrow from '../images/small-gold-arrow.svg';
import Hamburger from '../images/hamburger.svg';
import DividerComponent from '../components/divider';
import { Link } from 'gatsby';
import DesktopNavLinkComponent from '../components/desktop-nav-link';
import AnimateHeight from 'react-animate-height';

const HeaderContainer = () => {
  const links = [
    {
      title: 'GENERAL ENGLISH',
      children: [
        { title: 'ANGIELSKI OGÓLNY', link: '/angielski-ogolny' },
        { title: 'ANGIELSKI KONWERSACYJNY', link: '/angielski-konwersacje' },
      ],
    },
    {
      title: 'BUSINESS ENGLISH',
      children: [
        { title: 'ANGIELSKI BIZNESOWY', link: '/angielski-biznesowy' },
        { title: 'ANGIELSKI BRANŻOWY', link: '/angielski-branzowy' },
        { title: 'ROZMOWA O PRACĘ', link: '/rozmowa-o-prace' },
      ],
    },
    {
      title: 'EGZAMINY',
      children: [
        { title: 'EGZAMIN MATURALNY', link: '/kurs-maturalny' },
        { title: 'CERTYFIKATY CAMBRIDGE', link: '/certyfikaty-cambridge' },
        { title: 'STUDIA W UK & USA', link: '/studia-w-uk-i-usa' },
      ],
    },
    {
      title: 'DLA FIRM',
      children: [
        { title: 'PRACOWNICY', link: '/angielski-dla-firm' },
        { title: 'KADRA ZARZĄDZAJĄCA', link: '/executive-english' },
      ],
    },
    { title: 'TŁUMACZENIA', link: '/tlumaczenia' },
    {
      title: 'O NAS',
      children: [
        { title: 'MISJA I WARTOŚCI', link: '/o-nas' },
        { title: 'WSPÓŁPRACA', link: '/wspolpraca' },
      ],
    },
    { title: 'CENNIK', link: '/cennik' },
    { title: 'KONTAKT', link: '/kontakt' },
  ];
  const [isMobileMenuVisible, setIsMobileMenuVisible] = React.useState(false);
  const [currentLink, setCurrentLink] = React.useState(links);
  const [headerHeight, setHeaderHeight] = React.useState(0);

  const toggleMobileMenu = () => {
    const header1Height = document.getElementById('header1').offsetHeight;
    const header2Height = document.getElementById('header2').offsetHeight;
    const header3Height = document.getElementById('header3').offsetHeight;
    setHeaderHeight(window.innerHeight - header1Height - header2Height - header3Height);
    setIsMobileMenuVisible(!isMobileMenuVisible);
  };

  const getSublinkTitle = (firstSublinkTitle) => links.find((link) => link.children && link.children[0].title == firstSublinkTitle).title;

  return (
    <header className="w-full flex flex-col fixed top-0 z-30">
      <section id="header1" className="w-full h-2.5 lg:h-10 bg-blue-900 flex-shrink-0">
        <div className="hidden lg:flex container h-full mx-auto px-6 items-center justify-end">
          <a href="mailto:biuro@belgravia.edu.pl" className="flex items-center mr-8">
            <Envelope className="mr-2" />
            <div className="henderson-font text-2xs font-semibold text-white opacity-75">BIURO@BELGRAVIA.EDU.PL</div>
          </a>
          <a href="tel:+48 737 339 958" className="flex items-center">
            <Telephone className="mr-2" />
            <div className="henderson-font text-2xs font-semibold text-white opacity-75">+48 737 339 958</div>
          </a>
        </div>
      </section>
      <section id="header2" className="w-full h-20 lg:h-27 bg-white flex-shrink-0">
        <div className="container h-full mx-auto px-6 flex items-center justify-between">
          <Link to="/">
            <div className="text-2xl lg:text-3xl font-medium text-black">Belgravia</div>
          </Link>
          <nav className="hidden lg:flex flex-shrink-0 h-full">
            {links.map((link, i) => (
              <DesktopNavLinkComponent key={i} currentIndex={i} mainLinksLength={links.length} link={link} />
            ))}
          </nav>
          <div onClick={toggleMobileMenu} className="flex lg:hidden cursor-pointer">
            <Hamburger className="mr-5" />
            <div className="henderson-font text-xs text-black font-semibold">MENU</div>
          </div>
        </div>
      </section>
      <AnimateHeight duration={300} height={isMobileMenuVisible ? headerHeight : 0} animateOpacity={true} className="bg-white">
        <section className="relative bg-white h-full">
          <div className="container mx-auto p-6">
            {currentLink.length != links.length && (
              <div onClick={() => setCurrentLink(links)} className="flex items-center mb-7">
                <SmallGoldArrow className="mr-4 rotate-180 cursor-pointer" />
                <div className="w-full henderson-font text-xs text-black font-semibold cursor-pointer">
                  {getSublinkTitle(currentLink[0].title)}
                </div>
              </div>
            )}
            {currentLink.map((link, i) => (
              <div key={i} className="flex justify-between items-center mb-7">
                {link.link ? (
                  <Link to={link.link} className="w-full">
                    <div
                      className={`w-full henderson-font text-xs text-black font-semibold cursor-pointer ${
                        currentLink.length != links.length && 'opacity-75 ml-8'
                      }`}
                    >
                      {link.title}
                    </div>
                  </Link>
                ) : (
                  <div
                    onClick={() => setCurrentLink(link.children)}
                    className="w-full henderson-font text-xs text-black font-semibold cursor-pointer"
                  >
                    {link.title}
                  </div>
                )}
                {link.children && <SmallGoldArrow />}
              </div>
            ))}
          </div>
        </section>
      </AnimateHeight>
      <section id="header3" className="border-b border-gray-300">
        <section className="container mx-auto px-6 flex-shrink-0">
          <DividerComponent className="bg-gray-100" />
        </section>
      </section>
    </header>
  );
};

export default HeaderContainer;
